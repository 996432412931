import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
     component: () => import('@/views/home/index'),
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
  	path: "/list/:id",
  	name: "List",
  	meta: {
  		title: '文章列表'
  	},
  	component: () => import("../views/List")
  },
  {
  	path: "/listInfo/:id",
  	name: "ListInfo",
  	meta: {
  		title: '详细信息'
  	},
  	component: () => import("../views/ListInfo")
  },
  {
  	path: "/helpPro",
  	name: "HelpPro",
  	meta: {
  		title: '救助项目'
  	},
  	component: () => import("../views/HelpPro")
  },
  {
  	path: "/helpList/:id",
  	name: "HelpList",
  	component: () => import("../views/HelpList")
  },
  {
  	path: "/helpInfo/:id",
  	name: "HelpInfo",
  	component: () => import("../views/HelpInfo")
  },
  {
  	path: "/search",
  	name: "Search",
  	meta: {
  		title: '捐赠查询'
  	},
  	component: () => import("../views/Search")
  },

]

const router = new VueRouter({
  routes
})


// router.beforeEach((to,from,next)=>{
//   next()
// })

// // 导航守卫限制路由跳转
// router.beforeEach((to, from, next) => { 
// 	if (to.path === '/') {
// 		next()
// 		var face_token = ''
// 		// localStorage.getItem("face_token");
// 	}
// 	if (!face_token) {
// 		next()
// 	} else {
// 		next('/')
// 	}
// })
// router  要跟你引用的路由名称一致

export default router
