<template>
	<div class="nav">
		<el-menu :default-active="$route.path" class="navBox" background-color="#d30200" text-color="#fff"
			active-text-color="#fff" mode="horizontal" @select="handleSelect" router>
			<template v-for="(item, index) in navMenuData">
				<el-menu-item :index="item.href" v-if="item.hasChild == 0">{{item.name}}
				</el-menu-item>
				<el-submenu :index="/list/+item.id" v-if="item.hasChild == 1">
					<template slot="title">{{item.name}}</template>
					<template v-for="items in item.children">
						<template v-if="items.module == 0">
							<el-menu-item :index="/list/+items.id">{{items.name}}</el-menu-item>
						</template>
						<template v-if="items.module == 1">
							<el-menu-item :index="'/'+items.href">{{items.name}}</el-menu-item>
						</template>
					</template>
				</el-submenu>
				
			</template>
		</el-menu>

	</div>
</template>
<script>
	export default {
		name: "NavMenu",
		data() {
			return {
				id: '',
				module: 0,
				navMenuData: [],
			};
		},
		created() {
			this.getNavListData();
		},

		methods: {
			handleSelect(key, keyPath) {
				// console.log(key, keyPath);
			},
			onRouteChanged() {
				let that = this
				that.path = that.$route.path
			},
			getNavListData() {
				this.$http.get("cms/categoryList")
					.then((res) => {
						this.navMenuData = res.data.result;
						// console.log('nav',res);
					})
			},
		},
		watch: {
			// 监测路由变化,只要变化了就调用获取路由参数方法将数据存储本组件即可
			'$route': 'getNavListData'
		}
	};
</script>

<style>
	.nav {
		width: 100%;
		background-color: #d30200;
	}

	.navBox {
		width: 1200px !important;
		height: 50px !important;
		margin: 0 auto !important;
	}

	.el-menu--horizontal>.el-menu-item {
		height: 50px !important;
		width: 150px !important;
		line-height: 50px !important;
		font-size: 16px !important;
	}

	.el-menu--horizontal>.el-submenu .el-submenu__title {
		width: 150px !important;
		height: 50px !important;
		line-height: 50px !important;
		font-size: 16px !important;
	}

	.el-menu--horizontal>.el-submenu .el-submenu__icon-arrow {
		display: none !important;
	}

	.el-menu.el-menu--horizontal {
		border: 0 !important;
	}
	
</style>
