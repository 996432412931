<template>
	<div id="app">
		<Header />
		<Nav />
		<router-view></router-view>
		<Footer />
	</div>
</template>

<script>
	import Header from "@/components/header";
	import Nav from "@/components/Nav";
	import Footer from "@/components/Footer";
	export default {
		name: 'app',
		components: {
			Header,
			Nav,
			Footer
		}
	}
</script>

<style>
	body ,p{
		margin: 0;
		padding: 0;
	}
	
	#app {
		margin: 0;
		padding: 0;
		font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
	}
	.el-input__inner{
		height: 50px!important;
		outline: none!important;
	}
	.el-table .cell{
		line-height:30px!important;
	}
	.el-descriptions :not(.is-bordered) .el-descriptions-item__cell{
		width: 17%!important;
	}

	.el-message__icon{
		font-size: 35px!important;
	}
	.el-message__content{
		font-size: 35px!important;
		font-weight: bold;
	}
	.el-pagination {
	    white-space: nowrap;
	    padding: 24px 5px!important;
	    color: #303133;
	    font-weight: 700;
		margin: 0 auto;
		display: table;
	}
	.el-pagination.is-background .el-pager li:not(.disabled).active {
	    background-color: #da251c!important;
	    color: #FFF;
	}
	.el-pagination.is-background .el-pager li:not(.disabled):hover {
	    color: #fff!important;
		 background-color: #da251c!important;
	}
</style>
